.bg-gradient-info {
  background: #622761 !important;
}

.bg-info {
  background: #622761 !important;
}

.bg-default {
  background: #622761 !important;
}

.navbar-vertical .navbar-brand-img,
.navbar-vertical .navbar-brand > img {
  max-height: none;
}

.text-primary {
  color: #622761 !important;
}

.mb-7 {
  margin-bottom: 3rem !important;
}

.pt-lg-9,
.py-lg-9 {
  padding-top: 5rem !important;
}

/*Search bar*/
.search-header {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.25rem;
  padding-bottom: 0;
}

.search-categories {
  width: 100%;
  padding: 1.25rem 1.5rem;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border-radius: 0.25rem;
}

.search-categories li {
  list-style: none;
  display: inline-block;
  margin-right: 1rem;
  margin-top: 0.5rem;
}

/*Modal delete*/
.modal-danger-title,
.modal-delete-body,
.heading-delete {
  color: white;
}
.card-body form label {
  margin-bottom: 4px !important;
}
.navbar-vertical.navbar-expand-xs {
  z-index: 1050;
}
.paciente-existente {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background: #fff;
  z-index: 1060;
}
.body-overlay {
  z-index: 1060;
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.container-paciente {
  float: none;
  width: 60%;
  display: block;
  margin: 0 auto;
}
.container-paciente .card {
  box-shadow: none;
}
.container-paciente .card-header {
  width: 100%;
}
.container-paciente .card-header .ni-bell-55 {
  font-size: 30px;
}
.container-paciente .card-header h3 {
  font-size: 20px;
  margin-bottom: 20px;
}
.container-paciente .card-header h3 span {
  position: relative;
  top: -8px;
}
.container-paciente .card-header a {
  color: #d1d1d1;
  padding: 20px 0;
  display: block;
}
.container-paciente .table-responsive {
  margin: 20px;
  width: 96%;
}
.container-paciente .table-responsive .table .thead-light th {
  background: #fff;
}
.fc-content .fc-time {
  color: #fff;
}

.fc-time-grid-event.fc-short .fc-time,
.fc-time-grid-event.fc-short .fc-title {
  display: flex;
  justify-content: center;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #e4e4e4;
}

@media screen and (min-width: 300px) and (max-width: 900px) {
  .container-paciente {
    float: none;
    width: 95%;
    display: block;
    margin: 0 auto;
  }
  .container-paciente .table-responsive {
    overflow-x: inherit;
  }
}

.select2-selection__placeholder,
.select2-selection__rendered {
  font-size: 0.85rem;
  color: #adb5bd;
}

.dropdown-icon {
  color: #ced4da !important;
}

.dropdown-btn {
  box-shadow: none;
  background-color: transparent;
  border: none;
}

.table-responsive {
  height: auto;
}

.input-icon-disabled {
  background-color: #e9ecef;
}

.name-index {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  box-shadow: none;

  /*
  background: transparent;
    border: none;
    box-shadow: none;
    */
}

.name-index:hover {
  background: transparent;
  border: none;
  box-shadow: none;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.subcategoria-actions {
  padding-left: 2.7rem !important;
}

.positivo {
  color: darkseagreen !important;
}

.negativo {
  color: crimson !important;
}

.bg-extrato-hf {
  background-color: #eeeef1;
}

.extrato-descricao {
  max-width: 50%;
  overflow: hidden;
}

.mw-8r {
  min-width: 8rem;
}

.prev-fatura {
  background-color: #5e72e4;
  max-width: 2rem;
  max-height: 2rem;
  border-radius: 6px;
}

.next-fatura {
  background-color: #5e72e4;
  max-width: 2rem;
  max-height: 2rem;
  border-radius: 6px;
}

.nav-tabs .nav-link {
  border-color: #d7d7db;
}

.bank-img {
  max-width: 2rem;
  margin-right: 1rem;
}

.loader-card {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 9555;
  opacity: 0.8;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.spinner-card {
  opacity: 1;
  position: absolute;
  z-index: 999999;
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-left-color: #5e72e4;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
.spinner-card-text {
  position: relative;
  top: 40px;
  font-size: 13px;
}

.rounded-lg {
  border-radius: 10px;
}

.message:active {
  margin-top: 0.1rem;
}

.select2-search--dropdown {
  display: none;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 25rem;
}

.select2-container .select2-selection--single:focus,
.select2-container--default.select2-container--focus
  .select2-selection--multiple:focus,
.select2-container--default .select2-selection--multiple:focus,
.select2-container--default
  .select2-search--dropdown
  .select2-search__field:focus {
  border-color: #dee2e6;
}
.select-institution .select2-results > .select2-results__options {
  max-height: 25rem;
}

#date-input-personalizado {
  text-align: center;
}

.breadcrumb-item + .no-before-after::before {
  content: none;
}

.no-before-after + .no-before-after::before {
  content: none;
}
