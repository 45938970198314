//
// Header
//

.header {
    position: relative;
}

.bg-info {
  background-color: none;
  background: linear-gradient(87deg, #5e72e4 0, #825ee4 100%) !important;
}


